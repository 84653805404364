import React from 'react';
import logo from './bmv.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h3>
          The world's first proof-of-friendship based coin!
        </h3>
        <p>
          We're launching soon! In the meantime please direct inquiries to <a href='mailto:press@btcmv.org'>press@btcmv.org</a>
        </p>
      </header>
    </div>
  );
}

export default App;
